import Enlang from './entries/en-US';
import Ptlang from './entries/pt_BR';
//import { addLocaleData } from 'react-intl';

const AppLocale = {
  en: Enlang,
  pt: Ptlang,
};
//addLocaleData(AppLocale.en.data);
//addLocaleData(AppLocale.pt.data);

export default AppLocale;
