import antdSA from 'antd/lib/locale-provider/pt_BR';
//import appLocaleData from 'react-intl/locale-data/pt';
import saMessages from '../locales/pt_BR.json';

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: antdSA,
  locale: 'pt',
  //data: appLocaleData,
};
export default saLang;
