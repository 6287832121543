var axios = require('axios');
console.log('process.env', process.env);

var axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  // process.env.REACT_APP_API_HOST
  /* other custom settings */
});

axiosInstance.interceptors.request.use(async (config) => {
  
  const token = await localStorage.getItem('id_token');

  config.headers.Authorization = `Bearer ${token}`;
  return config;
}, (error) => {
  return Promise.reject(error);
});


export default axiosInstance;