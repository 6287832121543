import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import api from '@iso/config/api.config';

const history = createBrowserHistory();
const fakeApiCall = false; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    const { username, password, token } = payload;
    console.log('LOGIN_REQUEST', username, password, token);
    let userToken = '';

    const parameters = {
      username,
      password,
      token,
    };
    try {
      const response = yield api.post('users/login', parameters);
      if (response.data.user.admin) {
        userToken = response.data.token;
      }
    } catch (error) {
      console.log(error);
    }

    if (userToken !== '') {
      // console.log('save token');
      // localStorage.setItem('id_token', userToken);
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: userToken,
        profile: 'Profile',
      });
    } else {
      yield put({ type: actions.LOGIN_ERROR });
      alert('login ou senha inválido');
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    clearToken();
    yield put(history.push('/'));
    document.location.reload(true);
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken();
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
