import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import themes from '@iso/config/theme/theme.config';
import AppLocale from '@iso/config/translation';

export default function AppProvider({ children }) {
  const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const { themeName } = useSelector(state => state.ThemeSwitcher.changeThemes);
  const currentAppLocale = AppLocale[locale];
  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeName]}>
          <GoogleReCaptchaProvider
            reCaptchaKey="6LeEOnYdAAAAAIaMahY_Uzt812kPDKiBwQ2Am1YY"
            language="pt-BR"
            
            useEnterprise="true"
            scriptProps={{
              async: false, // optional, default to false,
              defer: false, // optional, default to false
              appendTo: 'head', // optional, default to "head", can be "head" or "body",
              nonce: undefined // optional, default undefined
            }}
          >
            {children}
          </GoogleReCaptchaProvider>
        </ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
